<template>
  <XtxDialog
    :title="title"
    :visible="visible"
    @closeDialog="
      () => {
        visible = false;
      }
    "
  >
    <div v-if="loading" class="loading"></div>
    <!-- 註單明细表格 -->
    <div class="table-con">
      <table class="m-table">
        <tr>
          <th style="width: 200px">時间</th>
          <th style="width: 130px">投註类别</th>
          <th style="width: 260px">號碼</th>
          <th style="width: 80px">賠率</th>
          <th style="width: 140px">投註金額</th>
          <th style="width: 140px">盈虧</th>
          <th style="width: 140px">佣金</th>
          <th style="width: 140px">總盈虧</th>
        </tr>
        <tr v-for="(item, index) in records.data" :key="index">
          <td style="width: 200px">
            {{ formatTime(item.gmtCreate, "YYYY-MM-DD HH:mm:ss") }}
          </td>
          <td style="width: 130px">{{ item.betTypeName }}</td>
          <td style="width: 260px">{{ item.betContent }}</td>
          <td
            style="width: 80px"
            v-if="item.betContent.indexOf(',') > 0 && gameCode < 20000"
          >
            内详
          </td>
          <td style="width: 140px" v-else>
            {{ item.currentOdds }}
          </td>
          <td style="width: 140px">{{ item.betMoney }}</td>
          <td style="width: 140px">{{ item.winMoney }}</td>
          <td style="width: 140px">
            {{ mathFloor(item.returnRate * item.betMoney, 100) }}
          </td>
          <td style="">
            {{
              item.status == 0
                ? 0
                : mathFloor(
                    item.winMoney +
                      item.betMoney * item.returnRate -
                      item.betMoney,
                    100
                  )
            }}
          </td>
        </tr>
        <tr v-if="records.total == 0">
          <td style="width: 100%" colspan="8">暫無數據</td>
        </tr>
      </table>
      <XtxPagination
        v-if="records.total > 0"
        :current-page="query.pageNo"
        :page-size="query.pageSize"
        :total="records.total"
        @current-change="pageChangeEvent"
      />
    </div>
  </XtxDialog>
</template>

<script>
import { reactive, ref, nextTick, getCurrentInstance } from "vue";
import { useStore } from "vuex";
export default {
  name: "BetReportDetail",
  props: {
    //弹窗标题
    title: {
      type: String,
      default: "註單確認",
    },
    //数据对象
    records: {
      type: Object,
      default: () => {
        return null;
      },
    },
    pager: {
      type: Object,
      default: () => {
        return { pageNo: 1, pageSize: 10 };
      },
    },
    //是否显示loading
    loading: {
      type: Boolean,
      default: false,
    },
    //游戏编码
    gameCode: {
      type: Number,
      default: 10000,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    /**
     *是否显示弹窗
     */
    let visible = ref(false);
    let query = reactive({
      pageNo: 1,
      pageSize: 10,
    });
    //打开弹窗钩子
    const open = (data) => {
      visible.value = true;
    };
    const pageChangeEvent = ($event) => {
      emit("page-change", $event);
    };
    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance()
        .appContext.config.globalProperties.$moment(new Date(time))
        .format(format);
    };
    const mathFloor = (num, multiple) => {
      return Math.floor(num * multiple) / multiple;
    };
    return {
      store,
      visible,
      open,
      query,
      pageChangeEvent,
      formatTime,
      mathFloor,
    };
  },
};
</script>

<style scoped lang="less">
.xtx-dialog {
  :deep(.wrapper) {
    width: 100%;
    min-width: 1000px;
    .body {
      font-size: 12px;
      padding: 5px 5px;
    }
  }
}
</style>
