<template>
  <div class="crm f-flex">
    <div class="crm-name">歷史報表</div>
  </div>
  <div class="crm f-flex">
    <div class="crm-tab f-flex">
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dateRank == 0 }"
        @click="tabChangeEvent(0)"
      >
        今日
      </div>
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dateRank == 1 }"
        @click="tabChangeEvent(1)"
      >
        昨日
      </div>
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dateRank == 7 }"
        @click="tabChangeEvent(7)"
      >
        本周
      </div>
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dateRank == 14 }"
        @click="tabChangeEvent(14)"
      >
        上周
      </div>
      <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dateRank == 30 }"
        @click="tabChangeEvent(30)"
      >
        本月
      </div>
      <!-- <div
        :class="{ 'crm-tab-li': true, 'crm-cur': query.dateRank == 60 }"
        @click="tabChangeEvent(60)"
      >
        上月
      </div> -->
    </div>

       
  </div>
   <div class="checkbox-box" style="display:inline-block; padding:0 15px 0 0;" v-for="item in records.games.filter(r=>{ return r.isOpenGame})" :key="item.value">
            <XtxCheckbox  v-if="isOpenGame(item.value)"  @change="changeGameEvent" v-model="item.checked">{{ item.key }}</XtxCheckbox>
          </div>
  <div v-if="loading" class="loading"></div>
  <div class="table-con">
    <table class="m-table">
      <tr>
        <th style="width: 120px">开獎日期</th>
        <th style="width: 120px">筆數</th>
        <th>投資金額</th>
        <th>盈虧</th>
        <th >退水</th>
        <th>總盈虧</th>
      </tr>
      <tbody v-if="records.data.length > 0">
        <tr
          v-for="(item, index) in records.data"
          :key="index"
          @click="reportDetailEvent(item.date)"
        >
          <td style="width: 120px">
            {{ formatTime(item.date, "YYYY-MM-DD") }}
          </td>
          <td style="width: 120px">{{ item.betNum }}</td>
          <td>{{ item.betMoney }}</td>
          <td>{{ item.userWinMoney }}</td>
          <td >{{ item.returnMoney }}</td>
          <td>{{ mathFloor(item.userWinMoney + item.returnMoney, 100) }}</td>
        </tr>
        <tr style="font-weight: bold">
          <td style="width: 120px">
            <span style="font-weight: bold">合计</span>
          </td>
          <td style="width: 120px">
            {{ mathFloor(sumBy(records.data, "betNum"), 100) }}
          </td>
          <td>{{ mathFloor(sumBy(records.data, "betMoney"), 100) }}</td>
          <td>{{ mathFloor(sumBy(records.data, "userWinMoney"), 100) }}</td>
          <td >{{ mathFloor(sumBy(records.data, "returnMoney"), 100) }}</td>
          <td>
            {{
              mathFloor(
                sumBy(records.data, "userWinMoney") +
                  sumBy(records.data, "returnMoney"),
                100
              )
            }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td style="width: 100%" colspan="6">暫無數據</td>
        </tr>
      </tbody>
    </table>
  </div>

   <div class="table-con">
    <table class="m-table">
      <tr>
        <th style="width: 120px">游戏</th>
        <th>投資金額</th>
        <th>盈虧</th>
        <th>總盈虧</th>
      </tr>
      <tbody v-if="records.gameItems.length > 0">
        <tr
          v-for="(item, index) in records.gameItems"
          :key="index"
        >
          <td style="width: 120px">
            {{ item.gameName }}
          </td>
          <td>{{ item.betMoney }}</td>
          <td>{{ item.userWinMoney }}</td>
          <td>{{ mathFloor(item.userWinMoney + item.returnMoney, 100) }}</td>
        </tr>
        
      </tbody>
    </table>
  </div>
  <!--明细列表弹窗 -->
  <BetReportDetailConfirm
    ref="reportDetail"
    :title="dialog.title"
    :records="dialog.records"
    :loading="dialog.loading"
    :gameCode="dialog.gameCode"
    @page-change="reportPageChangeEvent"
  />
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import moment from "moment-timezone";
import sumBy from "lodash.sumby";
import BetReportDetailConfirm from "@/views/report/bet-report-detail-confirm.vue";
export default {
  name: "LhcMonthReport",
  components: { BetReportDetailConfirm },
  setup() {
    const store = useStore();
    // 加载中
    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });
          //玩家基本信息
    const playerInfo = computed(() => {
      return store.state.player.profile;
    });
    let dialog = reactive({
      title: "投注明细",
      records: {
        data: [],
        total: 0,
      },
      gmtExtDate: "",
      gameCode: Number(currentGame.value.code),
    });
    watch(
      () => [currentGame.value.code, currentGame.value.plat],
      () => {
        query.gameCode = currentGame.value.code;
         checkDefalutGame();
        requestBetMonthReport();
      }
    );
    const loading = ref(false);
    let query = reactive({
      dateRank: 0,
      gameCode: currentGame.value.code,
    });
    let records = reactive({
      data: [],
      gameItems:[],
      games: [
        { key: "澳門六合彩", value: 10001, checked: false,title: '澳六' },
            { key: "新澳六", value: 10004, checked: false,title: '新澳六' },
        { key: "香港六合彩", value: 10002, checked: false ,title: '港六'},
        { key: "宝岛六合彩", value: 10003, checked: false ,title: '台六'},
        { key: "福彩3D", value: 20001, checked: false ,title: '3D'},
        { key: "体彩P3", value: 20002, checked: false ,title: 'P3'},
      ],

    });

    onMounted(() => {
     checkDefalutGame();
      nextTick(() => {
        requestBetMonthReport();
      });
    });
  const checkDefalutGame=()=>{
      let games=records.games;
      for(var i=0;i< games.length;i++){
        
         games[i]["isOpenGame"]=isOpenGame(games[i].value)
        games[i].checked=currentGame.value.code==games[i].value;
      }
  }
    //月报表
    const requestBetMonthReport = () => {
      loading.value = true;
      store
        .dispatch("game/requestBetMonthReportAction", query)
        .then((res) => {
          loading.value = false;
          if (res && res.code == 0 && res.res.datas) {
             let datas=res.res.datas;
            for(var i=0;i<datas.length;i++){
              let date= new Date(datas[i].date);
              datas[i]["sortDate"]=date.getFullYear()+date.getMonth()+date.getDate();
            }

            records.data = sortByKey(datas,"sortDate");
            records.gameItems=res.res.gameItems
          } else {
            records.data = [];
          }
        })
        .then(() => {
          emitClearBetEvent();
        })
        .catch(() => {
          loading.value = false;
        });
    };

  const  sortByKey=(array,key)=>{
      return array.sort(function(a,b){
            var x = a[key];
            var y = b[key];
            return((x>y)?-1:((x<y)?1:0));
        })
    }
    const tabChangeEvent = (type) => {
      query.dateRank = type;
      requestBetMonthReport();
    };
    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance()
        .appContext.config.globalProperties.$moment(new Date(time))
        .format(format);
    };
    //明细弹窗
    const reportDetail = ref(null);
    const reportDetailEvent = (day) => {
      dialog.gmtExtDate = moment.tz
        .setDefault("Asia/Shanghai")(day)
        .format("YYYY-MM-DD");
      reportPageChangeEvent();
      reportDetail.value.open({});
    };
    const reportPageChangeEvent = (page = 1) => {
      dialog.loading = true;
      let checkgames=  records.games.filter(r=>{
        return r.checked;
      })
      let dto={
            gmtExtDate: dialog.gmtExtDate,
            gameCode: '',
          }
      if(checkgames){
        console.log(checkgames.length);
        if(checkgames.length>1){
             dto["gameCodes"]=checkgames.map(r=>r.value);
        }else{
         dto.gameCode=checkgames[0].value;
        }
      }
      store
        .dispatch("game/requestBetPageAction", {
          pageNo: page,
          pageSize: 10,
          dto:dto,
        })
        .then((res) => {
          dialog.loading = false;
          if (res && res.code == 0) {
            let datas=res.res.rows
            for(var i=0;i<datas.length;i++){
              datas[i]["betTypeName"]= getGameTitle(datas[i]["gameCode"])+"-"+ datas[i]["betTypeName"];
            }
            dialog.records.data = datas;
            dialog.records.total = res.res.total;
          } else {
            dialog.records.data = [];
            dialog.records.total = 0;
          }
        })
        .catch(() => {
          dialog.loading = false;
        });
    };
    const mathFloor = (num, multiple) => {
      return Math.floor(num * multiple) / multiple;
    };
     const isOpenGame = (gameCode) => {
      if (!playerInfo.value.hasOwnProperty("openGameCode")) {
        return false;
      } else {
        // console.log('playerInfo.value.openGameCode',playerInfo.value.openGameCode)
        return playerInfo.value.openGameCode.includes(gameCode);
      }
    };
     const changeGameEvent=(e)=>{
      let checkgames=  records.games.filter(r=>{
        return r.checked;
      })
      if(checkgames){
        query.gameCode= checkgames.map(r=>r.value).join(',');
         requestBetMonthReport();
      }
       
     }
    const getGameTitle=(gameCode)=>{
      let checkgames=  records.games.filter(r=>{
        return r.value==gameCode;
      })
      if(checkgames){
        return checkgames[0].title;
      }
       
     }
    return {
      dialog,
      reportDetail,
      sumBy,
      loading,
      records,
      query,
      mathFloor,
      formatTime,
      requestBetMonthReport,
      tabChangeEvent,
      reportDetailEvent,
      reportPageChangeEvent,
      isOpenGame,
      changeGameEvent,
      sortByKey,
      checkDefalutGame,
      getGameTitle
    };
  },
};
</script>
